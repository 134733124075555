.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}

.profile-slider {
  width: 100%;
  max-width: 500px;
}

.profile-slider .slick-slide {
  position: relative;
}

.profile-slider .slick-slide img {
  width: 100%;
  border-radius: 5px;
}

.profile-info {
  text-align: center;
}

.profile-info h3 {
  margin-top: 0;
}

.profile-info p {
  margin-bottom: 0;
}

/* Responsive Styles */

@media (max-width: 768px) {
  .profile {
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .profile-slider {
    max-width: 400px;
  }
  
  .profile-info h4 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .profile {
    padding: 5px;
    margin-bottom: 5px;
  }
  
  .profile-slider {
    max-width: 300px;
  }
  
  .profile-info h4 {
    font-size: 18px;
  }
}
