.Male-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
}

.profile-section {
    display: flex;
    justify-content: center;
}

.card-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}